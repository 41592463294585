import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  fetchPressReleasesByUser,
  selectUserPressReleases,
} from "../../store/client/pressReleaseFetchSlice";
import { useDispatch, useSelector } from "react-redux";
function DashboardDetails() {
  const user = useSelector((state) => state.auth.user);
  const userId = user?._id;
  const dispatch = useDispatch();
  const pressReleases = useSelector(selectUserPressReleases);

  useEffect(() => {
    if (userId) {
      dispatch(fetchPressReleasesByUser({ userId }));
    }
  }, [dispatch, userId]);
  return (
    <div className="container right-content">
      <div className="tab-content mt-3" id="myTabContent">
        <div className="tab-pane fade show active" id="home" role="tabpanel">
          <div className="">
            <h4 className="home-page-main-heading">Press Release</h4>
            <div className="row pd_dash">
              <div className="col-6 col-lg-4">
                <Link
                  to="/dashboard/press-releases/approved"
                  className="text-decoration-none"
                  style={{ display: "block" }}
                >
                  <div
                    className="div_1"
                    style={{
                      backgroundColor: "#f3eaf5",
                      border: "1px solid #dc8ee1",
                    }}
                  >
                    <div
                      className="icon"
                      style={{ backgroundColor: "#dc8ee1" }}
                    >
                      <i className="fa-regular fa-user" />
                    </div>
                    {pressReleases?.counts
                      ?.filter((item) => item?.status === "approved")
                      ?.map((item, index) => (
                        <p key={index} className="div_number">
                          {item?.count}
                        </p>
                      ))}

                    <p className="div_name">Live </p>
                  </div>
                </Link>
              </div>

              <div className="col-6 col-lg-4">
                <Link
                  to="/dashboard/press-releases/draft"
                  className="text-decoration-none"
                  style={{ display: "block" }}
                >
                  {" "}
                  <div
                    className="div_1"
                    style={{
                      backgroundColor: "#eff2ff",
                      border: "1px solid #7e7baf",
                    }}
                  >
                    <div
                      className="icon"
                      style={{ backgroundColor: "#7e7baf" }}
                    >
                      <i className="fa-regular fa-user" />
                    </div>
                    {pressReleases?.counts?.some(
                      (item) => item?.status === "draft"
                    ) ? (
                      pressReleases?.counts
                        ?.filter((item) => item?.status === "draft")
                        ?.map((item, index) => (
                          <p key={index} className="div_number">
                            {item?.count || 0}
                          </p>
                        ))
                    ) : (
                      <p className="div_number">0</p>
                    )}

                    <p className="div_name">Drafts</p>
                  </div>
                </Link>
              </div>
              <div className="col-6 col-lg-4">
                <Link
                  to="/dashboard/press-releases/unapproved"
                  className="text-decoration-none"
                  style={{ display: "block" }}
                >
                  {" "}
                  <div
                    className="div_1"
                    style={{
                      backgroundColor: "#f7f6dc",
                      border: "1px solid #b3b28a",
                    }}
                  >
                    <div
                      className="icon"
                      style={{ backgroundColor: "#b3b28a" }}
                    >
                      <i className="fa-regular fa-user" />
                    </div>

                    {pressReleases?.counts?.some(
                      (item) => item?.status === "draft"
                    ) ? (
                      pressReleases?.counts
                        ?.filter((item) => item?.status === "unapproved")
                        ?.map((item, index) => (
                          <p key={index} className="div_number">
                            {item?.count || 0}
                          </p>
                        ))
                    ) : (
                      <p className="div_number">0</p>
                    )}

                    <p className="div_name">Waiting Approval</p>
                  </div>
                </Link>
              </div>
              <div className="col-6 col-lg-4">
                <Link
                  to="/dashboard/press-releases/rejected"
                  className="text-decoration-none"
                  style={{ display: "block" }}
                >
                  {" "}
                  <div
                    className="div_1"
                    style={{
                      backgroundColor: "#e6f7ef",
                      border: "1px solid #628b7f",
                    }}
                  >
                    <div
                      className="icon"
                      style={{ backgroundColor: "#628b7f" }}
                    >
                      <i className="fa-regular fa-user" />
                    </div>

                    {pressReleases?.counts?.some(
                      (item) => item?.status === "draft"
                    ) ? (
                      pressReleases?.counts
                        ?.filter((item) => item?.status === "rejected")
                        ?.map((item, index) => (
                          <p key={index} className="div_number">
                            {item?.count || 0}
                          </p>
                        ))
                    ) : (
                      <p className="div_number">0</p>
                    )}

                    <p className="div_name">Rejected</p>
                  </div>
                </Link>
              </div>
              <div className="col-6 col-lg-4">
                <Link
                  to="/dashboard/press-releases/deleted"
                  className="text-decoration-none"
                  style={{ display: "block" }}
                >
                  {" "}
                  <div
                    className="div_1"
                    style={{
                      backgroundColor: "#e5f3f9",
                      border: "1px solid #2bb3e7",
                    }}
                  >
                    <div
                      className="icon"
                      style={{ backgroundColor: "#2bb3e7" }}
                    >
                      <i className="fa-regular fa-user" />
                    </div>

                    {pressReleases?.counts?.some(
                      (item) => item?.status === "draft"
                    ) ? (
                      pressReleases?.counts
                        ?.filter((item) => item?.status === "deleted")
                        ?.map((item, index) => (
                          <p key={index} className="div_number">
                            {item?.count || 0}
                          </p>
                        ))
                    ) : (
                      <p className="div_number">0</p>
                    )}

                    <p className="div_name">Deleted </p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardDetails;
