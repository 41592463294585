import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container, Spinner } from "react-bootstrap";

import {
  fetchPressReleasesByUser,
  selectStatusByUser,
  selectUserPressReleases,
} from "../store/client/pressReleaseFetchSlice";
import PressReleaseCard from "../component/client/PressReleaseCard";
import styles from "../css/PressReleaseCard.module.css";

function UserPressReleases() {
  const { status } = useParams();
  const user = useSelector((state) => state.auth.user);
  const userId = user?._id;
  const dispatch = useDispatch();
  const data = useSelector(selectUserPressReleases);
  const statusByUser = useSelector(selectStatusByUser);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (userId) {
      setLoading(true);
      dispatch(fetchPressReleasesByUser({ userId, status })).finally(() =>
        setLoading(false)
      );
    }
  }, [dispatch, userId, status]);

  if (loading) {
    return (
      <Container className="mt-4 ps-5 text-center">
        <Spinner animation="border" variant="primary" />
        <p>Loading press releases...</p>
      </Container>
    );
  }

  if (statusByUser === "failed") {
    return (
      <div className="mt-4 ps-5">
        <h2 className="mb-4 text-capitalize">
          {status === "unapproved" ? "Waiting for Approval" : status} Press
          Releases
        </h2>
        <p>
          No {status === "unapproved" ? "Waiting for Approval" : status} press
          releases found.
        </p>
      </div>
    );
  }

  return (
    <Container className="mt-4 ps-5">
      <h2 className="mb-4 text-capitalize">
        {status === "unapproved" ? "Waiting for Approval" : status} Press
        Releases
      </h2>
      {data?.pressReleases?.length > 0 ? (
        data?.pressReleases?.map((pressRelease) => (
          <div key={pressRelease?._id} className={styles.pressReleaseContainer}>
            <PressReleaseCard
              pressRelease={pressRelease}
              styles={styles}
              status={status}
            />
          </div>
        ))
      ) : (
        <p>
          {status === "unapproved"
            ? "Waiting for Approval"
            : `No ${status} press releases found.`}
        </p>
      )}
    </Container>
  );
}

export default UserPressReleases;
