import React, { useEffect, useState } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MdBookmark, MdBookmarkBorder } from "react-icons/md";
import { BiLike } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { flashAction } from "../../store/client/flashSlice";
import axios from "axios";
import { GoComment } from "react-icons/go";
import ViewIcon from "../icon/ViewIcon";
import { deletePressRelease } from "../../store/client/pressReleaseFetchSlice";
import { GiJewelCrown } from "react-icons/gi";

const PressReleaseCard = ({ pressRelease, styles, status = "" }) => {
  const [likes, setLikes] = useState(pressRelease?.likes?.length);
  const [isLiked, setIsLiked] = useState(false);
  const [isBookMarked, setIsBookMarked] = useState(false);
  const [animate, setAnimate] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const isSignin = useSelector((state) => state.auth.isSignin);
  const storedUserId = user?._id;

  const isUserPost =
    !!pressRelease?.userId && pressRelease.userId === storedUserId

  const options = { day: "2-digit", month: "short", year: "numeric" };
  const date = new Date(pressRelease?.createdAt).toLocaleDateString(
    "en-GB",
    options
  );

  useEffect(() => {
    if (storedUserId) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/api/press-release/${pressRelease?._id}/isLikeByUser/${storedUserId}`
        )
        .then((response) => {
          setIsLiked(response.data.isLiked);
        })
        .catch((error) => {
          console.error("Error checking if liked", error);
        });

      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/api/press-release/${pressRelease?._id}/isBookmarkedByUser/${storedUserId}`
        )
        .then((response) => {
          setIsBookMarked(response.data.isBookmarked);
        })
        .catch((error) => {
          console.error("Error checking if bookmarked", error);
        });
    }
  }, [pressRelease?._id, storedUserId]);

  const handleLike = async () => {
    if (!isSignin) {
      dispatch(
        flashAction.addFlash({
          message: "Please log in to like the post.",
          type: "Fail",
        })
      );
      return navigate("/signin");
    }

    try {
      if (!isLiked) {
        await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/press-release/${pressRelease?._id}/like/${storedUserId}`
        );
        setLikes(likes + 1);
        setIsLiked(true);
      } else {
        await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/press-release/${pressRelease?._id}/unlike/${storedUserId}`
        );
        setLikes(likes - 1);
        setIsLiked(false);
      }
    } catch (err) {
      console.error("Error handling like:", err);
    }
  };

  const handleSaveAsBookMark = async () => {
    if (!isSignin) {
      dispatch(
        flashAction.addFlash({
          message: "Please log in to bookmark the post.",
          type: "Fail",
        })
      );
      return navigate("/signin");
    }

    setAnimate(true);
    try {
      if (!isBookMarked) {
        await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/press-release/saveAsBookMark`,
          { pressReleaseId: pressRelease._id },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setIsBookMarked(true);
      } else {
        await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/press-release/unSaveAsBookMark`,
          { pressReleaseId: pressRelease._id },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setIsBookMarked(false);
      }
    } catch (err) {
      console.error("Error handling bookmark:", err);
    } finally {
      setTimeout(() => setAnimate(false), 1500);
    }
  };

  const handleLinkClick = (e, slug, approvedStatus) => {
    if (approvedStatus !== "approved" && approvedStatus !== "unapproved") {
      e.preventDefault();
      if (status === "draft") {
        navigate(`/dashboard/submit-press-release/edit/${slug}`);
      }
      dispatch(
        flashAction.addFlash({
          message: `This post is waiting for ${approvedStatus}`,
          type: "Info",
        })
      );
    }
  };

  const handleEdit = (slug) => {
    navigate(`/dashboard/submit-press-release/edit/${slug}`);
  };

  const handleUpgrade = (id) => {
    localStorage.setItem("draft-press-release-id", id);
    navigate(`/dashboard/press-release/select-price`);
  };

  const handleDelete = (postId) => {
    if (window.confirm("Are you sure you want to delete this post?")) {
      dispatch(deletePressRelease(postId));
    }
  };

  <div className={styles.textCenter}>
    <Link
      className={`${styles.bookmarkIcon} ${
        animate ? styles.bookmarkIconAnimated : ""
      }`}
      onClick={(e) => {
        e.preventDefault();
        handleSaveAsBookMark();
      }}
    >
      {isBookMarked ? (
        <MdBookmark
          size={24}
          style={{ color: "goldenrod", paddingTop: "3px" }}
        />
      ) : (
        <MdBookmarkBorder
          size={24}
          style={{ color: "gray", paddingTop: "3px" }}
        />
      )}
    </Link>
  </div>;

  return (
    <div className={styles.card} key={pressRelease?._id}>
      <Row className={styles.noGutters}>
        <Col md={4} xs={12} className="position-relative">
          {pressRelease.contentFor === "standard" && (
            <div
              style={{
                position: "absolute",
                top: "-28px",
                left: "-8px",
                zIndex: 10,
                color: "#D4AF37",
                fontSize: "32px",
                transform: "rotate(-45deg)",
              }}
            >
              <GiJewelCrown />
            </div>
          )}

          <Link
            to={`/${pressRelease?.category}-press-release/${pressRelease?.slug}`}
            style={{ textDecoration: "none", color: "inherit" }}
            onClick={(e) =>
              handleLinkClick(
                e,
                pressRelease?.slug,
                pressRelease?.approvedStatus
              )
            }
          >
            <Card.Img
              src={pressRelease?.featuredImg?.url || "/images/no-pic.jpg"}
              alt={pressRelease?.featuredImg?.fileName || "No Pic"}
              className={styles?.cardImg}
            />
          </Link>
        </Col>

        <Col md={8} xs={12} className={`${styles?.pressContainer} ps-4`}>
          <Card.Body className={styles.cardBody}>
            <Link
              to={`/${pressRelease?.category}-press-release/${pressRelease?.slug}`}
              style={{ textDecoration: "none", color: "inherit" }}
              onClick={(e) =>
                handleLinkClick(
                  e,
                  pressRelease?.slug,
                  pressRelease?.approvedStatus
                )
              }
            >
              <p className={styles.pressTitle}>
                {pressRelease?.heading ||
                  pressRelease?.summary.split(" ").slice(0, 20).join(" ")}
              </p>
            </Link>
            <div>
              <span className={styles.pressTimeAndCategory}>
                Posted under {pressRelease?.category} Press Release on {date} by{" "}
                {pressRelease?.userId?.name || pressRelease?.userId?.userName}
              </span>
              <p className={styles.pressSummary}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: `${
                      pressRelease?.summary.split(" ").slice(0, 25).join(" ") ||
                      pressRelease?.heading
                    }...`,
                  }}
                />
              </p>
            </div>

            <div className={styles.bottomSection}>
              <div className={styles.iconSection}>
                {pressRelease?.approvedStatus === "approved" ||
                pressRelease?.approvedStatus === "deleted" ? (
                  <>
                    <span className={styles.iconWrapper}>
                      <ViewIcon className={styles.viewIcon} />
                      {pressRelease?.views}
                    </span>
                    <span className={styles.iconWrapper}>
                      <BiLike
                        size={16}
                        onClick={handleLike}
                        className={`${styles.likeIcon} ${
                          isLiked ? styles.liked : styles.notLiked
                        }`}
                      />
                      {likes}
                    </span>
                    <span className={styles.iconWrapper}>
                      <Link
                        to={`/${pressRelease?.category}-press-release/${pressRelease?.slug}`}
                        style={{ textDecoration: "none", color: "inherit" }}
                        onClick={(e) =>
                          handleLinkClick(
                            e,
                            pressRelease?.slug,
                            pressRelease?.approvedStatus
                          )
                        }
                      >
                        <GoComment className={styles.likeIcon} size={16} />{" "}
                        {pressRelease?.comments}
                      </Link>
                    </span>
                  </>
                ) : (
                  <div
                    style={{
                      padding: "0.4rem",
                      borderRadius: "0.5rem",
                      backgroundColor: "#dbdbdb",
                      fontSize: "0.8rem",
                    }}
                  >
                    Pending Approval
                  </div>
                )}
              </div>

              {isUserPost && (
                <div className={styles.actionButtonContainer}>
                  {pressRelease?.approvedStatus !== "draft" ||
                    (pressRelease?.approvedStatus !== "deleted" &&
                      pressRelease?.contentFor !== "standard" && (
                        <button
                          onClick={() => handleUpgrade(pressRelease?._id)}
                          className={`${styles.actionButton} ${styles.upgradeButton}`}
                        >
                          <span className={styles.actionButtonText}>
                            Upgrade
                          </span>
                        </button>
                      ))}
                  {pressRelease?.approvedStatus !== "approved" &&
                    pressRelease?.approvedStatus !== "deleted" && (
                      <button
                        onClick={() => handleEdit(pressRelease?.slug)}
                        className={`${styles.actionButton} ${styles.editButton}`}
                      >
                        <span className={styles.actionButtonText}>Edit</span>
                      </button>
                    )}
                  {pressRelease?.approvedStatus !== "deleted" && (
                    <button
                      onClick={() => handleDelete(pressRelease?._id)}
                      className={`${styles.actionButton} ${styles.deleteButton}`}
                    >
                      <span className={styles.actionButtonText}>Delete</span>
                    </button>
                  )}
                </div>
              )}
            </div>
          </Card.Body>
        </Col>
      </Row>
    </div>
  );
};

export default PressReleaseCard;
