import React from "react";
import { Outlet } from "react-router-dom";
import UserLeftMenu from "./DashboardMenu";
import DashboardBottom from "./DashboardBottom";

function Dashboard() {
  return (
    <div className="container">
      <div className="dashboard_body_1">
        <UserLeftMenu />
        <Outlet />
        <DashboardBottom />
      </div>
    </div>
  );
}

export default Dashboard;
