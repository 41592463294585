import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

function Billing() {
  const [transactions, setTransactions] = useState([]);
  const user = useSelector((state) => state.auth.user);
  const userId = user?._id;

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/press-release/payment/get/user/${userId}`
        );
        setTransactions(response.data.data);
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    };

    fetchTransactions();
  }, []);

  return (
    <div className="container right-content">
      <h3 className="transaction-heading">Transaction Details</h3>

      <div className="table-container-transaction">
        <table>
          <thead>
            <tr>
              <th>Order ID</th>
              <th>Transaction ID</th>
              <th>Amount</th>
              <th>Date</th>
              <th>Payment Type</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {transactions.length > 0 ? (
              transactions.map((transaction, index) => (
                <React.Fragment key={transaction._id}>
                  <tr>
                    <td>{transaction.orderId}</td>
                    <td>
                      {transaction.paymentId !== "N/A"
                        ? transaction.paymentId
                        : "—"}
                    </td>
                    <td>₹{transaction.amount}</td>
                    <td>
                      {new Date(transaction.startDate).toLocaleDateString()}
                    </td>
                    <td>Razorpay</td>
                    <td>
                      <span className={`status-${transaction.status}`}>
                        {transaction.status}
                      </span>
                    </td>
                  </tr>
                  {index !== transactions.length - 1 && (
                    <tr>
                      <td colSpan="6">
                        <hr
                          style={{
                            margin: "1px 0",
                          }}
                        />
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))
            ) : (
              <tr>
                <td colSpan="6">No transactions found.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Billing;
