import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { flashAction } from "../../store/client/flashSlice";
import { toast } from "react-toastify";
import ModernLoader from "../shimmers/ModernLoader";

function SelectPrice() {
  const [plans, setPlans] = useState([]);
  const [features, setFeatures] = useState([]);
  const [loading, setLoading] = useState(true);
  const [paymentStatus, setPaymentStatus] = useState({
    success: null,
    error: null,
  });
  const [expandedPlanId, setExpandedPlanId] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const userId = user?._id;
  const [waiting, setwating] = useState();

  useEffect(() => {
    const fetchPlansAndFeatures = async () => {
      try {
        const [plansRes, featuresRes] = await Promise.all([
          axios.get(`${process.env.REACT_APP_BASE_URL}/api/plans/get`),
          axios.get(
            `${process.env.REACT_APP_BASE_URL}/api/plans/plan-feature-name/get`
          ),
        ]);

        setPlans(plansRes.data);
        setFeatures(featuresRes.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPlansAndFeatures();
  }, []);

  const handlePayment = async (amount, userId, termsAccepted, planId) => {
    if (!userId) {
      dispatch(
        flashAction.addFlash({
          message: "Please log in to proceed.",
          type: "Fail",
        })
      );
      navigate("/signin");
      return;
    }
    console.log(amount);

    const token = localStorage.getItem("token");
    const pressReleaseId = localStorage.getItem("draft-press-release-id");
    if (!pressReleaseId) {
      dispatch(
        flashAction.addFlash({
          message: "Please fill out the press release form first.",
          type: "Fail",
        })
      );

      navigate("/dashboard/submit-press-release");
      return;
    }

    if (amount === 0) {
      const response = await axios.put(
        `${
          process.env.REACT_APP_BASE_URL
        }/api/press-release/addToUnaprooved/${pressReleaseId}/${"basic"}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Create response:", response.data);
      toast.success("Press release created successfully!");
      setwating(true);

      localStorage.removeItem("draft-press-release-id");

      setTimeout(() => {
        navigate("/dashboard/press-releases/unapproved");
      }, 2000);

      return;
    }

    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/press-release/payment/create-order`,
        { amount, userId, planId, termsAccepted }
      );

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID,
        amount: data.order.amount,
        currency: "INR",
        name: "Press Release Payment",
        description: "Subscription Payment",
        order_id: data.order.id,
        handler: async (response) => {
          try {
            await axios.post(
              `${process.env.REACT_APP_BASE_URL}/api/press-release/payment/verify-payment`,
              {
                razorpay_order_id: response.razorpay_order_id,
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_signature: response.razorpay_signature,
                userId,
                planId,
                termsAccepted,
              }
            );

            setPaymentStatus({ success: true, error: null });

            const res = await axios.put(
              `${process.env.REACT_APP_BASE_URL}/api/press-release/addToUnaprooved/${pressReleaseId}/${response.razorpay_order_id}`,
              {},
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "multipart/form-data",
                },
              }
            );
            console.log("Create response:", res.data);
            toast.success("Press release created successfully!");

            localStorage.removeItem("draft-press-release-id");

            setTimeout(() => {
              navigate("/dashboard/press-releases/unapproved");
            }, 2000);
          } catch (error) {
            setPaymentStatus({
              success: false,
              error: "Transaction verification failed. Try again.",
            });
          }
        },
        theme: { color: "#3399cc" },
      };

      const rzp = new window.Razorpay(options);
      rzp.open();
    } catch (error) {
      setPaymentStatus({
        success: false,
        error: "Failed to initiate payment. Try again.",
      });
    }
  };

  const toggleFeatures = (planId) => {
    setExpandedPlanId(expandedPlanId === planId ? null : planId);
  };

  if (loading) {
    return <p>Loading plans...</p>;
  }

  if (waiting) {
    return (
      <>
        <ModernLoader />
      </>
    );
  }

  return (
    <section className="pricing-new-section">
      <div className="container">
        <div className="row justify-content-center">
        {expandedPlanId  &&  (
        <div className="col-12 col-md-4">
              <div className="price-plan-div" >
                <div>
                  <p className="planFeature-heading" style={{marginTop:"17rem"}}>Plan & Feature</p>


                  {plans[0]?.keyFeatures?.length > 0 && (
                    <div className="mt-3">
                      

                     
                        <div className="features-list">
                          {plans[0].keyFeatures.map((feature, index) => (
                            <div key={index} className="feature-item my-3">
                              <strong>{feature.name}</strong>
                            </div>
                          ))}
                        </div>
                      
                    </div>
                  )}
                </div>
              </div>
            </div>
            )}
          {plans.map((plan) => (
            <div key={plan._id} className="col-12 col-md-4">
              <div className="price-plan-div">
                <div>
                  <p className="plan-heading">{plan.planName}</p>
                  <p className="plan-para">{plan.description}</p>
                  <p className="plan-price">
                    {plan.price === 0 ? "Free" : `$${plan.price}`}
                  </p>

                  <button
                    className="get-started-btn"
                    onClick={() =>
                      handlePayment(plan.price, userId, true, plan._id)
                    }
                  >
                    Select Package
                  </button>

                  {plan?.keyFeatures?.length > 0 && (
                    <div className="mt-3">
                      <div
                        className="view-details-btn"
                        onClick={() => toggleFeatures(plan._id)}
                      >
                        {expandedPlanId
                          ? "Hide Details"
                          : "View Details"}
                      </div>

                      {expandedPlanId  && (
                        <div className="features-list">
                          {plan.keyFeatures.map((feature, index) => (
                            <div key={index} className="feature-item my-3">
                            {feature.value}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default SelectPrice;
