import axios from "axios";
import React, { useState, useRef, useEffect } from "react";
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import getTokenFromLocalStorage from "../../utils/common/getTokenFromLocalStorage";
import { flashAction } from "../../store/client/flashSlice";
import { updateUserDetails } from "../../store/client/authSlice";

function UserSettings() {
  const [isEditing, setIsEditing] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  const [formData, setFormData] = useState({
    name: user?.name || "",
    userName: user?.userName || "",
    bio: user?.bio || "",
    email: user?.email || "",
    phoneNo: user?.phoneNo || "",
    dob: user?.dob || "",
    address: {
      countryName: user?.address?.countryName || "",
    },
    website: user?.website || "",
    social_profile: user?.social_profile || [],
    profileImg: user?.profileImg || { fileName: "", url: "" },
  });

  useEffect(() => {
    if (user) {
      setFormData({
        name: user?.name || "",
        userName: user?.userName || "",
        bio: user?.bio || "",
        email: user?.email || "",
        phoneNo: user?.phoneNo || "",
        dob: user?.dob || "",
        address: {
          countryName: user?.address?.countryName || "",
        },
        website: user?.website || "",
        social_profile: user?.social_profile || [],
        profileImg: user?.profileImg || { fileName: "", url: "" },
      });
    }
  }, [user]);

  const token = getTokenFromLocalStorage();
  const bioRef = useRef(null);

  const handleEditToggle = () => {
    setIsEditing((prev) => !prev);
    if (bioRef.current) {
      bioRef.current.focus();
    }
  };

  const handleImageUpload = async (file) => {
    const formData = new FormData();
    formData.append("profileImg", file);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/user-profile/edit-avtar`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setFormData((prevData) => ({
        ...prevData,
        profileImg: response.data.profileImg || { fileName: "", url: "" },
      }));

      dispatch(
        flashAction.addFlash({
          message: "Profile image updated successfully!",
          type: "Success",
        })
      );


      dispatch(updateUserDetails());
    } catch (err) {
      dispatch(
        flashAction.addFlash({
          message: "Failed to update profile image. Please try again.",
          type: "Fail",
        })
      );
      console.log(err);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
      handleImageUpload(file);
    }
  };

  const handleSave = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/user-profile/edit-detail`,
        { formData },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setIsEditing(false);
      dispatch(
        flashAction.addFlash({
          message: "Profile updated successfully!",
          type: "Success",
        })
      );

      // Dispatch the updateUserDetails thunk to update Redux state and localStorage
      dispatch(updateUserDetails());

      console.log("Saved Data:", formData);
    } catch (err) {
      dispatch(
        flashAction.addFlash({
          message: "Failed to update profile. Please try again.",
          type: "Fail",
        })
      );
      console.log(err);
    }
  };

  const handleCancel = () => {
    if (user) {
      setFormData({
        name: user?.name || "",
        userName: user?.userName || "",
        bio: user?.bio || "",
        email: user?.email || "",
        phoneNo: user?.phoneNo || "",
        dob: user?.dob || "",
        address: {
          countryName: user?.address?.countryName || "",
        },
        website: user?.website || "",
        social_profile: user?.social_profile || [],
        profileImg: user?.profileImg || { fileName: "", url: "" },
      });
    }
    setIsEditing(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name.includes(".")) {
      const [parent, child] = name.split(".");
      setFormData((prevData) => ({
        ...prevData,
        [parent]: {
          ...prevData[parent],
          [child]: value,
        },
      }));
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleSocialInputChange = (e, index, field) => {
    const value = e.target.value;
    setFormData((prevData) => {
      const updatedProfiles = [...prevData.social_profile];
      updatedProfiles[index] = updatedProfiles[index] || {};
      updatedProfiles[index][field] = value;
      return { ...prevData, social_profile: updatedProfiles };
    });
  };

  const handleRemoveProfile = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      social_profile: prevData.social_profile.filter((_, i) => i !== index),
    }));
  };

  const handleAddProfile = () => {
    if (formData.social_profile.length === 0) {
      setFormData((prevData) => ({
        ...prevData,
        social_profile: [...prevData.social_profile, { name: "", url: "" }],
      }));
      return;
    }

    const lastProfile =
      formData.social_profile[formData.social_profile.length - 1];

    if (!lastProfile.name || !lastProfile.url) {
      dispatch(
        flashAction.addFlash({
          message:
            "Please fill out both Name and URL before adding another profile.",
          type: "Fail",
        })
      );
      return;
    }

    setFormData((prevData) => ({
      ...prevData,
      social_profile: [...prevData.social_profile, { name: "", url: "" }],
    }));
  };

  return (
    <div className="container right-content">
      <div className="update-profile">
        <h3 className="update-profile-h5">Update Profile</h3>
        <div className="mb-4">
          <img
            src={formData?.profileImg?.url || user?.profileImg?.url}
            alt="Profile img"
          />
          <label htmlFor="upload-image" className="change-pic-btn">
            Change Picture
            <input
              type="file"
              id="upload-image"
              accept="image/*"
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
          </label>
        </div>
        <div className="profile-form-div">
          <label htmlFor="name">Profile Name</label>
          <br />
          <input
            type="text"
            name="name"
            id="name"
            value={formData.name}
            onChange={handleInputChange}
          />
        </div>
        <div className="profile-form-div">
          <label htmlFor="userName">UserName</label>
          <br />
          <input
            type="text"
            name="userName"
            id="userName"
            value={formData.userName}
            onChange={handleInputChange}
          />
        </div>
        <h5 className="update-profile-h5-1">About Me</h5>
        <div className="row about-row-profile">
          <div className="col-12 profile-form-info-div">
            <textarea
              name="bio"
              id="bio"
              ref={bioRef}
              placeholder="Tell something about yourself in detail....."
              rows={4}
              cols={5}
              value={formData.bio}
              onChange={handleInputChange}
            />
          </div>
          <div className="col-6 profile-form-info-div">
            <label htmlFor="email">Email</label>
            <br />
            <input
              type="email"
              name="email"
              id="email"
              value={formData.email}
              onChange={handleInputChange}
            />
          </div>
          <div className="col-6 profile-form-info-div">
            <label htmlFor="phoneNo">Phone</label>
            <br />
            <input
              type="tel"
              name="phoneNo"
              id="phoneNo"
              value={formData.phoneNo}
              onChange={handleInputChange}
            />
          </div>
          <div className="col-6 profile-form-info-div">
            <label htmlFor="dob">Date of Birth</label>
            <br />
            <input
              type="date"
              name="dob"
              id="dob"
              value={formData.dob}
              onChange={handleInputChange}
            />
          </div>
          <div className="col-6 profile-form-info-div">
            <label htmlFor="address.countryName">Country</label>
            <br />
            <input
              type="text"
              name="address.countryName"
              id="country"
              value={formData.address.countryName}
              onChange={handleInputChange}
            />
          </div>
          <div className="col-6 profile-form-info-div">
            <label htmlFor="website">Website</label>
            <br />
            <input
              type="text"
              name="website"
              id="website"
              value={formData.website}
              onChange={handleInputChange}
            />
          </div>
          <div className="col-6" />
          <div className="col-6 profile-form-info-div">
            <label htmlFor="">Social Profiles</label>
            <br />
            {formData.social_profile && formData.social_profile.length > 0 ? (
              formData.social_profile.map((profile, index) => (
                <div key={index} className="social-profile-item mb-2">
                  <input
                    type="text"
                    placeholder="Platform Name"
                    value={profile.name || ""}
                    onChange={(e) => handleSocialInputChange(e, index, "name")}
                    className="mb-1 mr-2"
                  />
                  <input
                    type="text"
                    placeholder="Profile URL"
                    value={profile.url || ""}
                    onChange={(e) => handleSocialInputChange(e, index, "url")}
                    className="mb-1 mr-2"
                  />
                  <button
                    type="button"
                    onClick={() => handleRemoveProfile(index)}
                    className="btn btn-sm btn-danger"
                  >
                    <MdDelete />
                  </button>
                </div>
              ))
            ) : (
              <p>No Social Profiles Added Yet</p>
            )}
            <button
              type="button"
              className="add-new-profile-btn"
              onClick={handleAddProfile}
            >
              Add New Profile
            </button>
          </div>
          <div className="">
            <button className="save-profile-btn" onClick={handleSave}>
              Save
            </button>
            <button className="cancel-profile-btn" onClick={handleCancel}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserSettings;
