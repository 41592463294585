import React from "react";
import { RiSendPlaneFill } from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";

function DashboardMenu() {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <div className="left-menu">
      <div
        className={`menu-item ${currentPath === "/dashboard" ? "active" : ""}`}
      >
        <Link to="/dashboard" className="text-decoration-none">
          <span>My Press Release</span>
        </Link>
      </div>
      <div
        className={`menu-item ${
          currentPath === "/dashboard/billings" ? "active" : ""
        }`}
      >
        <Link to="/dashboard/billings" className="text-decoration-none">
          <span>Billings</span>
        </Link>
      </div>
      <div
        className={`menu-item ${
          currentPath === "/dashboard/user-settings" ? "active" : ""
        }`}
      >
        <Link to="/dashboard/user-settings" className="text-decoration-none">
          <span>Settings</span>
        </Link>
      </div>
      <div
        className={`menu-item ${
          currentPath === "/dashboard/submit-press-release" ? "active" : ""
        }`}
      >
        <Link
          to="/dashboard/submit-press-release"
          className="text-decoration-none"
        >
          <p className="menu-button">
            <RiSendPlaneFill size={16} className="me-1" />
            Submit Press Release
          </p>
        </Link>
      </div>
    </div>
  );
}

export default DashboardMenu;
